.errortxtBTN{
    color: rgb(177, 30, 30);
    font-size: 14px;
}
.errorBTNContainer{
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    margin-left: 10px;
}

.rawContainer{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
}
.rawContainerDiagrams{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
}
.centerdMargin{
    margin: 10px auto;
}
.gauseContainer{
    width: 200px;
    height: 220px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
}
.gauseTitle{
    color: white;
}

/* PercentageDisplay.css */
.percentage-container {
    display: flex;
    align-items: center;
    padding: 16px;
    background: #142141(62, 29, 151);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    width: 400px;
    border: 1px solid #a8b0c0;
    margin-top: 8px;
    z-index: 80;
  }
  
  .percentage-container-VT {
    display: flex;
    align-items: center;
    padding: 16px;
    background: #142141(62, 29, 151);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    width: 400px;
    border: 1px solid #a8b0c0;
    margin-top: 8px;
    z-index: 80;
  }
  .percentage-value {
    font-size: 56px;
    font-weight: bold;
    color: white;
    width: 100px;
  }
  
  .percentage-label {
    font-size: 14px;
    color: #a8b0c0;
    width: 300px;
    text-align:left;
  }

  .percentage-value-VT {
    font-size: 34px;
    font-weight: bold;
    color: white;
  }
  
  .percentage-label-VT {
    font-size: 14px;
    color: #a8b0c0;
    width: 600px;
    text-align:left;
    margin-left: 15px;
  }
.containerDetStats{
    width: 300px;
    background: #142141(62, 29, 151);
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    background: #142141(62, 29, 151);
    box-shadow: 0 6px 8px rgb(19, 10, 49);
    margin: 5px 15px 5px 0px;
}
.containerDetStatsAll{
    width: 270px;
    background: #142141(62, 29, 151);
    padding: 10px;
    border-radius: 10px;
    background: #142141(62, 29, 151);
    box-shadow: 0 6px 8px rgb(19, 10, 49);
    margin: 5px 15px 5px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.containerDetStatsTitle{
    font-size: 20px;
    margin-top: 20px;
    color: #ffffff;
}
.containerDetStatsBody{
    width: 250px;
    margin: 0 auto;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100px;
}
.containerDetStatsBodyAll{
    width: 235px;
    margin: 10px auto 0px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: flex-start;
    height: 120px;
}
.containerDetStatsTitleAll{
    font-size: 20px;
    margin-top: 20px;
    margin-left: 15px;
    color: #ffffff;
}
.statrowAll{
    width: 220px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
}
.statrow{
    width: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.valueDetStats{
    font-size: 18px;
    font-weight: bold;
    color: white;
    width: 100px;
  }
  
  .labelDetStats{
    font-size: 14px;
    color: #a8b0c0;
    width: 300px;
    text-align:left;
  }
  .labelDetStatsAll{
    font-size: 14px;
    color: #a8b0c0;
  }
.percentage-container-Coverage{
    display: flex;
    align-items: center;
    padding: 16px;
    background: #142141(62, 29, 151);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    border: 1px solid #a8b0c0;
    margin-top: 8px;
    z-index: 80;
}
.percentage-value-Coverage{
    font-size: 56px;
    font-weight: bold;
    color: white;
}
.rawContainerCenteredV{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
}
.rawContainerCenteredH{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
}
.diagrammHeadTitle{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    color: white;
    font-size: 20px;
}
.detectorDiagramsContainer{
    width: 90%;
    position: relative;
    margin: 0 auto;
}
.detectorDiagramsContainerRaw{
    width: 45%;
    min-width: 380px;
    position: relative;
    margin: 0 auto;
}
.diagramContainer{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
}
.scrollable-100{
    width: 100%;
    overflow-x: auto;
    padding: 20px;
}

.modal-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-direction: flex-start;
    width: 100%;
    height: auto;
}

.customizeScroll{
    color: purple;
}

.page-title{
    color: white;
    font-size: 26px;
    margin: 10px 0;
}

.startsumo-container{
    width: 100%;
    max-width: 900px;
    padding: 12px 14px;
    border-radius: 4px;
    box-shadow: -1px 2px 4px 1px rgb(0, 0, 34);
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.sumofilters{
    width: 650px;
    display: flex;  
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
}
.remote-display{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 1200px;
    box-shadow: -1px 2px 3px 3px rgb(0, 0, 34);
    border-radius: 4px;
    padding: 20px;
    margin: 15px;
}
.remote-display-title{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: white;
    font-size: 20px;
    margin: 10px 0;
}
.remote-display-body{
    width: 100%;
    height: 800px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0;
}