.filterContainer{
    width: 50%;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 5px;
}
.colContainer{
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
}
.colContainerHalf{
    width: 50%;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
}
.filterStepperDS{
    width: 20%;
    min-width: 550px;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
}
.filterStepper{
    width: 20%;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
}
.searchBtn{
    margin-top: 50px;
}
.filterTitles{
    font-size: 18px;
    color: rgb(206, 206, 206);
    margin: 10px 0;
}
.vline {
    border-left: 1px solid white;
    align-self: stretch; /* Optional: let the second child stretch to match the parent's height */
    padding-left: 20px;
}
.rawDPicker{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.fromtodp{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 10px;
}
.filterNtw{
    width: 70%;
    min-width: 500px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
}

.filterLocal{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.filterLocal span{
    color: rgb(206, 206, 206);
}
.bodyDisplay{
    width: 100%;
    display: flex; 
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
    margin: 10px 0;
}
.bodyDisplay .diagrams{
    width: 65%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.bodyDisplay .mapSection{
    width: 550px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
}