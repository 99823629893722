.notifications-container {
    width: 100%;
    height: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px;
}


.notification-card {
    background: #202940;
    padding: 8px;
    border-radius: 6px;
    box-shadow: 1px 2px 7px rgb(182, 174, 174);
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    width: 100%;
    height: auto;
    max-height: 150px;
}

.notification-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
}

.notification-icon {
    font-size: 20px;
}

.notification-title {
    flex: 1;
    margin: 0 10px;
    font-size: 14px;
    font-weight: bold;
}

.dismiss-btn {
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    color: white;
}

.notification-body {
    text-wrap: wrap;
    font-size: 12px;
    color: white;
    height: auto;
    max-height: 220px; /* Adjust height as needed */
    overflow-y: auto;  /* Enables vertical scrolling if content exceeds max height */
}

.notification-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: rgb(150, 150, 150);
    margin-top: 5px;
}

.notification-actions {
    display: flex;
    gap: 5px;
}

.action-btn {
    background: #455786;
    color: white;
    border: none;
    padding: 4px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
}
.action-btn-dissmiss {
    background: white;
    color: #455786;
    border: 1px solid #455786;
    padding: 4px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
}
.action-btn-dissmiss:hover {
    background: #007bffa6;
    color: white;

}
.action-btn:hover {
    background: #007bffa6;
}

.create-container{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
}

.create-input{
    width: 100%;
    max-width: 600px;
    padding: 12px 14px;
    border-radius: 4px;
    box-shadow: -1px 2px 4px 1px rgb(0, 0, 34);
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.input-label{
    color: white;
    font-size: 18px;
}