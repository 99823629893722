.menu-items-mod span{
    line-height: 1  !important;
    text-wrap: auto !important;
}

/* Universal scrollbar styles */
::-webkit-scrollbar {
    height: 5px; /* Set scrollbar height for horizontal scrolling */
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light background */
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #888; /* Scrollbar color */
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker on hover */
  }